
/* body {
  background-color: #95C1D0;
} */
  
  form {
    margin-top: 3%;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
    width: 90%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
    color: #555;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
  }
  
  input:focus,
  textarea:focus {
    border-color: #4caf50;
  }
  
  button {
    width: 100%;
    padding: 12px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .invoice {
    margin-top: 20px;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  
  /* Responsive Styles */
  @media only screen and (max-width: 768px) {
    form {
      padding: 15px;
    }
  }
  
  .slide-pane {
    margin-left: 25%;
    min-width: 10%;
    max-width: 50%;
    min-height: 20%;
    max-height: 60%;
    margin-top: 6%;
  }